<template v-if="refreshPage">
    <div class="row">
        <PageTitle :title="'Profil vendor'" />
    </div>
    <div id="cardProfile" class="card mb-5 mb-xl-10">
        <div class="card-body p-9">
            <div class="row mb-7">
                <div class="col-lg-8 fw-bold text-muted d-flex flex-wrap flex-sm-nowrap">
                    <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                        <img :src="profile != null ? $mix.generateAvatar(profile.data.vendor_name) : '#'"
                            style="border-radius:50%" alt="image" />
                    </div>
                    <div class="flex-grow-1 d-flex align-items-center" style="margin-left:30px">
                        <!--begin::Title-->
                        <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
                            <!--begin::User-->
                            <div class="d-flex flex-column">
                                <!--begin::Name-->
                                <div class="d-flex align-items-center mb-2">
                                    <div class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"> <span
                                            id="valueFullname">{{ profile != null ? profile.data.vendor_name : ''}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--begin::Info-->
                <div class="col-lg-4" align="right">
                    <span
                        :class="`badge text-green-800 badge-lg ${profile != null ? profile.data.is_active == true ? 'badge-success' : 'badge-secondary' : ''}`">{{
                        profile != null ? profile.data.is_active == true ? 'Aktif' : 'Tidak Aktif' : ''}}</span>
                    &nbsp;&nbsp;
                    <!-- <span :class="`badge badge-lg ${profile != null ? profile.data.allow_approval != true ? 'badge-secondary' : 'badge-success' : ''}`">{{profile != null ? profile.data.allow_approval != true ? 'Tidak dapat melakukan approval' : 'Dapat melakukan approval' : ''}}</span> -->
                </div>
            </div>

            <div class="row mb-7 mt-5">
                <label class="col-lg-4 fw-bold text-muted">Email</label>
                <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-dark">{{ profile != null ? profile.data.personal_email : ''}}</span>
                </div>
            </div>
            <div class="separator separator-dashed my-5"></div>

            <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Email SSO</label>
                <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-dark">{{ profile != null ? profile.data.personal_email : ''}}</span>
                </div>
            </div>
            <div class="separator separator-dashed my-5"></div>

            <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">No Telepon</label>
                <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-dark">{{ profile != null ? profile.data.no_tlp : ''}}</span>
                </div>
            </div>
            <div class="separator separator-dashed my-5"></div>

            <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Alamat</label>
                <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-dark">{{ profile != null ? profile.data.address : ''}}</span>
                </div>
            </div>

            <div class="separator separator-dashed my-5"></div>

            <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Tipe Vendor</label>
                <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-dark">{{ profile != null ? profile.data.vendor_type_name : ''}}</span>
                </div>
            </div>

            <el-form @submit.prevent="submit()" id="form" ref="formRef" class="form" :rules="rules" :model="model"
                :label-width="fields.label_width">
                <div class="separator separator-dashed my-5"></div>
                <div align="right">
                    <button id="btnChangePass" @click="goBack" class="btn btn-secondary">Kembali</button>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import PageTitle from '@/components/PageTitle.vue'
export default {
    components: {
        PageTitle,
    },
    data() {
        return {
            profile: JSON.parse(this.$store.getters.currentUser),
            department_name: null,
            posturl: '/user/v1/update-user',
            loading: false,
            fields: {
                label_position: 'left', //right or left
            },
            rules: {
                signature_file_name: [
                    {
                        required: true,
                        message: "Tanda tangan wajib di isi",
                        trigger: "change",
                    }
                ]
            },
            model: {
                id: null,
                username: null,
                employee_id: null,
                name: null,
                email: null,
                branch_id: null,
                department_code: null,
                signature_file_name: null,
                signature_base64: null,
            },
            refreshPage: true,
            users: null,
            showForm: false,
        }
    },
    mounted() {
        if (this.$store.getters.isUserAuthenticated) {
            if (this.profile.data.path_signature == null) {
                this.showForm = true
            } else {
                this.showForm = false
            }
        } else {
            this.$router.replace({ name: "sign-in" });
        }
        setCurrentPageBreadcrumbs('Profile', [
            {
                title: "User",
                link: '#'
            }
        ])
    },
    methods: {
        openFile(link) {
            this.$apiservice.setHeader();
            this.$apiservice.query('/reference/v1/get-file', { params: { Url: link } }).then(res => {
                window.open(res.request.responseURL, '_blank')
            })
        },
        fetchMe() {
            this.$apiservice.setHeader();
            this.$apiservice.get('/user/v1/me').then(res => {
                let data = res.data
                window.localStorage.setItem('user', JSON.stringify(data))
                this.$router.go(this.$router.currentRoute)
            })
        },
        submit() {
            if (!this.$refs.formRef) {
                return;
            }
            this.$refs.formRef.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.$refs.btnSimpan.setAttribute("data-kt-indicator", "on");
                    this.$refs.btnSimpan.disabled = true;
                    setTimeout(() => {
                        this.$apiservice.setHeader();
                        this.$apiservice.put(this.posturl, this.model).then(res => {
                            if (res.data.error) {
                                Swal.fire({
                                    title: "Gagal",
                                    text: res.data.message,
                                    icon: "error",
                                    buttonsStyling: false,
                                    confirmButtonText: "Oke",
                                    customClass: {
                                        confirmButton: "btn btn-primary",
                                    },
                                })
                            } else {
                                Swal.fire({
                                    title: 'Berhasil diperbarui',
                                    text: "Anda telah berhasil memperbarui tanda tangan",
                                    icon: "success",
                                    buttonsStyling: false,
                                    confirmButtonText: "Ok, got it!",
                                    customClass: {
                                        confirmButton: "btn btn-primary",
                                    },
                                }).then(res => {
                                    this.fetchMe()
                                })
                            }

                        }).catch(err => {
                            if (err.response.status == 401) {
                                this.$router.push({ name: '401' })
                            } else if (err.response.status == 404) {
                                this.$router.push({ name: '404' })
                            } else if (err.response.status == 500) {
                                this.$router.push({ name: '500' })
                            } else {
                                if (err.response.data.message) {
                                    Swal.fire({
                                        title: "Gagal",
                                        text: err.response.data.message,
                                        icon: "error",
                                        buttonsStyling: false,
                                        confirmButtonText: "Oke",
                                        customClass: {
                                            confirmButton: "btn btn-primary",
                                        },
                                    })
                                    this.loading = false
                                    return false;
                                }
                            }
                        }).finally(() => {
                            this.loading = false
                            this.$refs.btnSimpan.removeAttribute("data-kt-indicator");
                            this.$refs.btnSimpan.disabled = false;
                        })
                    }, 1000);
                } else {
                    Swal.fire({
                        text: "Data yang anda masukkan gagal ditambahkan",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Oke",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    })
                    this.loading = false
                    this.$refs.btnSimpan.removeAttribute("data-kt-indicator");
                    this.$refs.btnSimpan.disabled = false;
                    return false;
                }
            })
        },
        goBack() {
            return this.$router.go(-1)
        },
        changePassword() {
            this.$router.push({ path: '/change-password' })
        },
        forceUpdate() {
            this.refreshPage = false
            this.$nextTick(() => {
                this.refreshPage = true
            })
        },
        changeFile(val, key, keyurl) {
            let files = val.target.files[0]
            if (files.type == 'image/jpeg' || files.type == 'image/png' || files.type == 'image/jpg') {
                if (files.size > 10000000) {
                    Swal.fire({
                        text: "Gagal, ukuran file maksimal 10MB",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Oke",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    })
                } else {
                    this.model[key] = files.name
                    this.createBase64url(files, keyurl)
                }
            } else {
                Swal.fire({
                    text: "Gagal, format file harus .jpg, .png, .img",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Oke",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                })
            }
        },
        createBase64url(files, keyurl) {
            var reader = new FileReader();
            reader.onload = function (e) {
                // this.model[keyurl] = e.target.result
                // this.model[keyurl] = e.target.result.replace('data:', '')
                this.model[keyurl] = e.target.result.replace('data:', '').replace(/^.+,/, '')
            }.bind(this)
            reader.onerror = function (error) {
                alert(error);
            };
            reader.readAsDataURL(files)
        },
    }
}
</script>